/* Print styles for thefridgedubai.com */

@media print {

    /* General */

    @page {
        margin: 0.5cm;
    }

    html {
        padding: 0 10px;
    }

    body {
        height: auto !important;
/*        overflow: visible !important;*/
    }

    *, * *,*:before, *:after {
        /*
        background-color: transparent!important;
        */
        color: #000!important;
        /* Black prints faster: h5bp.com/s */
        /*
        box-shadow: none;
        text-shadow: none;
        border: none!important;
        box-shadow: none!important;
        min-height: auto!important;
        height: auto!important;
        font-size: 16px!important;
        text-indent: 0!important;
        */
    }

    /*	abbr[title]:after {
    content: " (" attr(title) ")";
}
    */

    a[href] {
        text-decoration: none;
    }

    a[href]:after {
        display: none;
    }

    /*
    * Don't show links for images, or javascript/internal links
    */

    sub,
    sup {
        font-size: 0.6875rem !important;
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    .page-break {
        page-break-before: always;
        page-break-inside: avoid;
    }

}

@media print,
    (-o-min-device-pixel-ratio: 5/4),
    (-webkit-min-device-pixel-ratio: 1.25),
    (min-resolution: 120dpi) {
        /* Style adjustments for high resolution devices */
}