/* Custom CSS for thefridgedubai.com */

/* General */
html {
    overflow-x: hidden;
    overflow-y: scroll;
}

body {
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    &.fixed {
        position: fixed;
        width: 100%;
        overflow: hidden;
    }
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.center {
    margin: 0 auto;
}

.right {
    float: right;
}

.pos-rel {
    position: relative;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-weight: 700;

    @include breakpoint(medium down) {
        margin-bottom: 1.6rem;
    }

    &:only-child {
        margin-bottom: 0;
    }
}

.h1 {
    line-height: 1.33;
}

.h2 {
    line-height: 1.33;
}

.h4 {
    line-height: 1.27;
}

p, .p, li {
    font-size: rem-calc(14);
    text-transform: none;
}

.p12 {
    font-size: rem-calc(12);
}

.p16 {
    font-size: rem-calc(16);
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-t-1 {
    margin-top: 1rem !important;
}

.m-t-2 {
    margin-top: 2rem !important;
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.m-b-1 {
    margin-bottom: 1rem !important;
}

.m-b-1-5 {
    margin-bottom: 1.5rem !important;
}

.m-b-2 {
    margin-bottom: 2rem !important;
}

.m-b-3 {
    margin-bottom: 3rem !important;
}

.m-b-3-5 {
    margin-bottom: 3.5rem !important;
}

.p-0 {
    padding: 0 !important;
}

.no-style {
    margin-left: 0;
    list-style: none;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-underline {
    text-decoration: underline;
}

.text-bold {
    font-weight: 700;
}

.align-right {
    float: right;
}

i.fa,
i.fas
{
    font-size: rem-calc(18);
}

.black-color {
    color: $black;
}

.white-color {
    color: $white;
}

.primary-color {
    color: $primary-color;
}

.secondary-color {
    color: $secondary-color;
}

.tertiary-color {
    color: $tertiary-color;
}

.medium-gray-color {
    color: $medium-gray;
}

.bg-white {
    background: $white;
}

.bg-primary {
    background: $primary-color;
}

.bg-secondary {
    background: $secondary-color;
}

.bg-tertiary {
    background: $tertiary-color;
}

.bg-light-gray {
    background-color: $light-gray !important;
}

.bg-light-medium-gray {
    background: $light-medium-gray;
}

.bg-pattern {
    background: $tertiary-color url('/assets/img/bg-pattern-min.png');
}

.border-bottom {
    padding-bottom: rem-calc(20);;
    border-bottom-color: $medium-gray;
    border-bottom-style: solid;

    &.border-bottom-1 {
        border-bottom-width: 1px;
    }

    &.border-bottom-2 {
        border-bottom-width: 2px;
    }

}

::selection {
    background: $tertiary-color;
}

a {
    font-weight: 600;
}

*:focus {
    outline: 0;
    /*color: inherit;*/
}

input:required,
select:required {
    -moz-box-shadow: none;
    box-shadow: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.awesomplete {

    @include breakpoint(medium down) {
        width: 100%;
    }

    > ul {
        border-radius: 0;
        margin: .2em 0 0;
        background: $white;
        border: 0;
        box-shadow: none;
    }

    li {
        color: $black;
    }

    mark {
        /*background: $tertiary-color;*/
        background: none;
        color: $primary-color;
        font-weight: 700;
    }

}

body {
    font-size: 14px;

    /* General */

    .section  {
        position: relative;

        > .grid-container {

            > .grid-x {

            }

        }

        &.intro {
            padding: 112px 0 105px;

            @include breakpoint(medium down) {
                padding: 40px 0;
            }
        }

        &.default {
            padding: 102px 0 102px;

            &.extended {

                &.top {
                    padding-top: 171px;
                }

                &.bottom {
                    padding-bottom: 200px;
                }

            }

            &.padding-y {
                padding: 70px 0 78px;
            }

            @include breakpoint(medium down) {
                padding: 60px 0;

                &.extended {

                    &.top {
                        padding-top: 121px;
                    }

                    &.bottom {
                        padding-bottom: 115px;
                    }

                }

                &.padding-y {
                    padding: 33px 0 33px;
                }
            }

            &.register-options {
                padding: 70px 0 108px;

                @include breakpoint(medium down) {
                    padding: 33px 0 58px;
                }

            }

            &.cta {
                padding: 40px 0 60px;

                @include breakpoint(medium down) {
                    padding: 30px 0 60px;
                }
            }

        }

        &.home-beneficiaries {
            padding: 223px 0 100px;

            @include breakpoint(medium down) {
                padding: 241px 0 60px;
            }

        }

        &.cta {
            padding: 28px 0 27px;

            @include breakpoint(medium down) {
                padding: 30px 0;
            }
        }

        &.carousel {
            padding: 72px 0;

            @include breakpoint(medium down) {
                padding: 40px 0;
            }
        }

        &.profile {

            > .grid-container {

                > .grid-x {

                    @include breakpoint(medium down) {
                        margin-left: -1.25rem;
                        margin-right: -1.25rem;
                    }

                    > .large-3 {

                        &:before {
                            position: absolute;
                            top: 0;
                            margin-left: -100%;
                            background: #fff;
                            content: '';
                            width: 100%;
                            height: 100%;

                            @include breakpoint(medium down) {
                                content: none;
                            }
                        }

                    }

                    > .large-9 {
                        padding: 53px 0 100px 32px;

                        @include breakpoint(medium down) {
                            padding: 60px 20px;
                        }
                    }

                }

            }

        }

    }

    p, ul, ol {

        &:last-child {
            margin-bottom: 0;
        }
    }

    .button {
        margin: 0;
        border: 0;
        width: 200px;
        font-weight: 600;

        @include breakpoint(medium down) {
            /*width: 280px;*/
            /*width: 100%;*/
        }

        &.primary {
            color: $secondary-color;

            &.hollow-hover {

                &:hover {
                    border: 2px solid $primary-color !important;
                    background: transparent !important;
                    line-height: 41px;
                    color: $primary-color !important;
                }

            }
        }

        &.secondary:not(.hollow) {

            &:hover {
                background: $tertiary-color !important;
            }

            &.hollow-hover {

                &:hover {
                    border: 2px solid $secondary-color !important;
                    background: transparent !important;
                    line-height: 41px;
                    color: $secondary-color !important;
                }

            }
        }

        &.clear {
            padding: 1.05rem 0 1.05rem;
            width: auto;

            i {
                position: relative;
                top: 2px;
                margin-right: 7px;
            }
        }

        &.auto {
            padding-left: 30px !important;
            padding-right: 30px !important;
            width: auto;
        }

        &.rounded {
            border-radius: 22.5px;
            padding: 0;
            height: 45px;
            line-height: 45px;
        }

        &.hollow {
            line-height: 41px;

            &:hover {
                background: $primary-color !important;
                border-color: $primary-color !important;
                color: $secondary-color !important;
            }

            &.hollow-hover {
                &:hover {
                    border-color: $tertiary-color !important;
                    background: transparent !important;
                    color: $tertiary-color !important;
                }
            }

        }

        &.round {
            border-radius: 50%;
            padding: 0;
            width: 40px;
            height: 40px;
            line-height: 40px;

            i,span {
                font-size: rem-calc(20);
                line-height: 2;

                @include breakpoint(medium down) {
                    font-size: rem-calc(16);
                    line-height: 1;
                }

            }
        }

    }

    .close-button {
        color: $white;
        font-size: 3rem;
    }

    /* Header */

    > header {
        position: absolute;
        margin-top: 36px;
        width: 100%;
        z-index: 3;

        @include breakpoint(medium down) {
            margin-top: 0;
        }

        .sticky-container {
            @include breakpoint(medium down) {
                @include breakpoint(landscape) {
                    height: auto !important;
                }
            }
        }

        .section {

            .logo {
                position: relative;
                top: -1px;
				        display: block;
                width: 202px;
                height: 60px;
                transition: all ease 0.25s;

                @include breakpoint(medium down) {
                    top: 0;
                    width: 154px;
                }

                svg {
                    width: 100%;
                }

            }

            @include breakpoint(large up) {
                .grid-container {
                    padding-right: rem-calc(40);
                    padding-left: rem-calc(40);
                }
            }

            .grid-x {
                @include breakpoint(medium down) {
                    height: 63px;
                }
            }

            @at-root .stuck,
            &.is-stuck,
            &.is-menu-open
            {
                margin-top: 0;
                background: $primary-color;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
                z-index: 9999999;

                /*@include breakpoint(medium down) {
                    @include breakpoint(landscape) {
                        position: relative !important;
                    }
                }*/

                .grid-x {
                    height: 79px;

                    @include breakpoint(medium down) {
                        height: 63px;
                    }
                }

                .logo {
                    width: 169px;

                    @include breakpoint(medium down) {
                        width: 134px;
                    }

                    @include breakpoint(medium up) {
                        svg {
                            path {
                                fill: $tertiary-color;
                            }
                        }
                    }

                }

            }

            > .grid-container {
                overflow-x: visible;
                overflow-y: visible;
            }

        }

        .menu {

            [data-toggle="responsive-menu"] {
                position: relative;
                top: -1px;
                left: 1px;
                padding: 0;
            }

            .hamburger {

                &:hover{
                    cursor: pointer;
                }

                .line {
                    display: block;
                    margin: 5px auto;
                    background-color: $white;
                    width: 28px;
                    height: 3px;
                    transition: all 0.3s ease-in-out;
                }

                &.is-active {

                    .line {

                        &:nth-child(2) {
                            opacity: 0;
                        }

                        &:nth-child(1) {
                            transform: translateY(8px) rotate(45deg);
                        }

                        &:nth-child(3) {
                            transform: translateY(-8px) rotate(-45deg);
                        }

                    }

                }

            }

        }

        .top-bar {
            display: inline;

            @include breakpoint(medium down) {
                position: fixed;
                top: 63px;
                left: 0;
                display: none;
                background: $tertiary-color;
                padding: 26px 0 32px;
                width: 100vw;
                height: auto;
                z-index: 1;
                overflow: scroll;

                .logo {

                    @include breakpoint(medium down) {
                        width: 154px;
                    }

                }
            }

            .menu {

                @include breakpoint(medium down) {
                    display: block;
                    padding: 0 20px;
                }

                li {
                    position: relative;
                    margin-left: 15px;
                    color: $white;
                    font-size: rem-calc(14);

                    &:nth-child(1) {
                        margin-right: 15px;
                    }

                    span {
                        margin-right: 5px;
                    }

                    a {

                        &.clear {
                            padding: 0;
                            color: $white;

                            &:hover {
                                color: $tertiary-color;
                            }
                        }

                        &.secondary {

                            &:hover {
                                background: $primary-color !important;
                                color: $white !important;
                            }

                        }

                        &.hollow {
                            border-color: $white;
                            color: $white;

                            &:hover {
                                border-color: $primary-color !important;
                                background: transparent !important;
                                color: $primary-color !important;
                            }
                        }


                        @include breakpoint(medium down) {
                            padding: 17px 0 15px;
                            width: 100%;
                            line-height: 1.2;

                            &:focus,
                            &:hover {
                                background: $light-medium-gray;
                                color: $primary-color;
                                overflow: hidden;

                                &:before {
                                    position: absolute;
                                    top: 0;
                                    left: -100%;
                                    background: $light-medium-gray;
                                    width: 300%;
                                    height: 100%;
                                    content: "";
                                }

                                /*&:after {
                                    position: absolute;
                                    top: 0;
                                    right: -100%;
                                    background: $light-medium-gray;
                                    width: 100%;
                                    height: 100%;
                                    content: "";
                                }*/
                            }

                            span {
                                position: relative;
                            }

                            i {
                                position: relative;
                                float: right;

                                &:first-child {
                                    float: none;

                                    &:before {
                                        position: absolute;
                                        top: -12px;
                                    }

                                }

                            }

                            i.fas + span {
                                padding-left: 28px;
                            }
                        }

                    }

                    @include breakpoint(medium down) {
                        margin: 0 0 15px !important;
                        padding: 0;
                        justify-content: start;

                        &.border-top {
                            margin: 40px 0 15px !important;

                            a {
                                &:after {
                                    position: absolute;
                                    top: -20px;
                                    left: 0;
                                    background: #555555;
                                    content: "";
                                    width: 100%;
                                    height: 1px;
                                }
                            }

                        }

                        &:last-child {
                            margin-bottom: 0 !important;
                        }

                    }

                }

                .close-button {
                    font-size: rem-calc(42);
                }

            }

        }

        &.dashboard {

            .sticky:not(.is-stuck) {

                .top-bar {

                    .menu {

                        li {

                            a {

                                &.clear {

                                    &:hover {
                                        color: $primary-color;
                                    }

                                }

                            }

                        }

                    }

                }

            }

        }

    }

    /* Footer */

    > footer {

        .section {
            position: relative;

            &:first-child {
                padding: 43px 0 42px;

                @include breakpoint(medium down) {
                    padding: 55px 0 59px;
                }
            }

            .sticky-container {
                position: absolute;
                width: 100%;

                .button {

                    include breakpoint(medium down) {
                        &.round {
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                        }
                    }

                    &:not(.is-stuck) {
                        position: absolute;
                        right: 3% !important;
                        top: auto !important;
                        bottom: 61px !important;

                        @include breakpoint(medium down) {
                            bottom: 80px !important;
                        }
                    }

                    &.is-stuck {
                        margin-top: 1em;
                        left: auto;
                        right: 3%;
                    }
                }

            }

            .grid-container {
                position: relative;

                ul {
                    margin: 0;
                    list-style: none;

                    li {
                        display: inline-block;
                        font-size: rem-calc(12);
                    }
                }

                a {
                    color: $white;

                    &:hover {
                        color: $tertiary-color;
                    }
                }

                .links {

                    ul {

                        @include breakpoint(medium down) {
                            margin-bottom: 29px;
                        }

                        li {

                            i {
                                margin-left: 3px;
                                margin-right: 22px;
                                font-size: rem-calc(24);

                                @include breakpoint(medium down) {
                                    font-size: rem-calc(18);
                                }
                            }

                            &:last-child {
                                i {
                                    margin-right: 0;
                                }
                            }

                        }
                    }
                }

                .menu {

                    ul {
                        @include breakpoint(medium down) {
                            margin: 0 auto 11px;
                            text-align: center;
                        }

                        li {

                            @include breakpoint(medium down) {
                                display: block;
                                margin-bottom: 17px;
                            }

                            a {
                                padding-top: 0;
                                padding-bottom: 0;
                                font-weight: 600;
                            }

                        }

                    }

                }

                .copyright {

                    p {
                        margin: 0;
                        color: $white;
                        letter-spacing: 0.9px;
                        font-size: rem-calc(12);

                        @include breakpoint(medium down) {
                            line-height: 1.33;
                        }

                        a {
                            color: $primary-color;
                            text-align: center;
                        }
                    }
                }

            }

        }

    }

    /* Content */

    .page {

        /* General */

        .breadcrumb {
            position: absolute;
            top: 54px;

            @include breakpoint(medium down) {
                top: 29px;
                left: 0;
                right: 0;
                line-height: 1.29;
            }
        }

        video {
            width: 100%;
            max-width: 720px;
        }

        .thumbnail {
            max-width: 281px;
            max-height: 281px;
        }

        .icon {
            border-radius: 1000px;
            padding: 19px;

            &.fas {
                font-size: rem-calc(40);
            }

        }

        .callout {

            a {
                color: $secondary-color;

                &:hover {
                    color: $tertiary-color;
                }
            }

            &.search-box {
                padding: rem-calc(15) rem-calc(30) rem-calc(15);

                input {
                    margin-bottom: 0;
                    padding-right: 40px;
                }

                + .table-scroll {
                    position: relative;
                    margin-bottom: 0;
                    height: auto;
                    /*height: 75%;*/
                }
            }

        }

        /* Sections */

        .section {

            /* Banners */

            $banner-height: calc(100vh);

            &.banner {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: cover;
                height: $banner-height;

                @include breakpoint(medium down) {
                    height: 420px;

                    @include breakpoint(landscape) {
                        height: calc(100vh);
                    }
                }

                .grid-container {
                    width: 100%;
                    z-index: 1;
                }

                .button.scroll-to-content {
                    margin: 0 auto;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 30px;

                    @include breakpoint(medium down) {
                        bottom: 12px;
                    }

                    /*@include breakpoint(medium down) {
                        @include breakpoint(landscape) {
                            display: none;
                        }
                    }*/
                }

                &.home {
                    /*background-image: url('/assets/img/banner-home.jpg');

                    @include breakpoint(large up) {
                        background-image: url('/assets/img/banner-home-lg.jpg') !important;
                    }*/
                    transition: background, opacity 550ms ease-in-out;

                    .mobile,
                    .desktop {
                        position: absolute;
                        opacity: 0;
                        background-position: 50%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 100%;
                        height: 100%;
                        text-indent: -99999px;
                        transition: background, opacity 550ms ease-in-out;

                        &.show {
                            opacity: 1;
                            text-indent: 0;
                        }

                    }

                    h1 {
                        margin-top: 10%;
                        margin-bottom: 0;
                        /*font-size: rem-calc(58);*/
                        line-height: 1.12;

                        @include breakpoint(medium down) {
                            /*font-size: rem-calc(28);*/
                            line-height: 1.14;
                        }
                    }
                }

                &.profile {
                    /*background-image: url('/assets/img/banner-profile.jpg');*/
                    height: 320px;
                    transition: background, opacity 550ms ease-in-out;

                    .mobile,
                    .desktop {
                        position: absolute;
                        opacity: 0;
                        background-position: 50%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 100%;
                        height: 100%;
                        text-indent: -99999px;
                        transition: background, opacity 550ms ease-in-out;

                        &.show {
                            opacity: 1;
                            text-indent: 0;
                        }

                    }

                    @include breakpoint(large up) {
                        /*background-image: url('/assets/img/banner-profile-lg.jpg') !important;*/
                        height: 450px;
                    }
                }

                &.dashboard {
                    background-position: 0 0;
                    background-repeat: repeat;
                    background-size: auto;
                    height: 221px;

                    @include breakpoint(large up) {
                        height: 346px;
                    }

                    p {
                        margin-top: rem-calc(50);
                        margin-bottom: rem-calc(17);

                        @include breakpoint(medium down) {
                            margin-top: rem-calc(39);
                            margin-bottom: rem-calc(11);
                            font-size: rem-calc(14);
                        }
                    }

                    h1 {
                        margin-bottom: 0;

                        &:first-child {
                            margin-top: rem-calc(110);
                        }

                    }

                    a {

                        &:focus,
                        &:hover {
                            border-bottom: 1px solid $primary-color;
                            color: $primary-color;
                        }

                    }
                }
            }

            /* Default Block */

            &.default {

            }

            /* Carousel */

            &.carousel,
            .carousel {

                .tns-outer {
                    position: relative;

                    .slider {
                        display: block;

                        /*@include breakpoint(medium down) {
                            margin-left: 1.25rem;
                        }*/
                    }

                    .tns-controls {
                        position: absolute;
                        width: 100%;
                        height: 100%;

                        [data-controls="prev"],
                        [data-controls="next"] {
                            position: absolute;
                            top: 48%;
                            transform: translateY(-50%);

                            .button {
                                @include breakpoint(medium down) {
                                    width: 24px;
                                    height: 24px;
                                    line-height: 20px;

                                    i {
                                        font-size: rem-calc(12);
                                    }
                                }
                            }
                        }

                        [data-controls="prev"] {
                            left: -60px;
                            z-index: 1;

                            @include breakpoint(large only) {
                                left: -30px;
                            }

                            @include breakpoint(medium down) {
                                left: -17px;
                            }

                            i {
                                margin-right: 3px;
                                line-height: 1.85;
                            }
                        }

                        [data-controls="next"] {
                            right: -60px;
                            z-index: 1;

                            @include breakpoint(large only) {
                                right: -30px;
                            }

                            @include breakpoint(medium down) {
                                right: -17px;
                            }

                            i {
                                margin-left: 3px;
                                line-height: 1.85;
                            }
                        }

                    }

                }

                .tns-inner {

                    @include breakpoint(large up) {
                        margin-left: 1px !important;
                    }

                }

                .slider {
                    display: none;
                    margin-left: 0;
                    list-style: none;

                    img {
                        max-width: 261px;
                        max-height: 261px;
                        width: 100%;

                        @include breakpoint(large up) {
                            max-width: 258px;
                        }
                    }
                }

            }

            /* Home Beneficiaries */

            &.home-beneficiaries {
                position: relative;

                .callout {
                    position: relative;
                    top: -317px;
                    margin-bottom: -220px;
                    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.35);
                    border: 0;

                    @include breakpoint(medium down) {
                        margin-left: -20px;
                        margin-right: -20px;
                        margin-bottom: -235px;
                        padding: 46px 20px 53px;
                        /*width: 115%;*/
                    }

                }

                form {

                    input {
                        margin-right: 0;
                        margin-bottom: 20px;
                    }

                    .awesomplete {
                        /*width: 535px;*/
                        width: 100%;

                        /*@include breakpoint(large only) {
                        width: 359px;
                    }

                        @include breakpoint(medium down) {
                        width: 100%;
                    }*/

                    }

                    select {
                        margin-right: 0;
                        margin-bottom: 20px;
                        width: 245px;
                        max-width: 535px;
                    }

                    button {
                        margin-bottom: 20px;
                        padding: 0 50px;
                    }

                }

            }

            /* Scrolling Table */

            .table-scroll {
                margin-bottom: -19%;
                background: $white;
                border-style: solid;
                border-color: $white;
                border-width: 26px 0 26px 30px;
                height: 100%;
                overflow-x: hidden;

                @include breakpoint(medium down) {
                    margin-bottom: 0;
                    border-width: 0;

                    &.expanded {
                        padding: 5px 20px 0;
                        max-height: 377px;
                    }
                }

                .empty {
                    margin-bottom: 0;

                    @include breakpoint(medium down) {
                        padding: 10px 0 13px;
                    }
                }

                .grid-x {

                    &.accordion {
                        margin-right: -20px;

                        .accordion-item {

                            .accordion-title {
                                margin-bottom: 1rem;
                                padding: 0;
                                font-weight: 700;
                                pointer-events: none;

                                i {
                                    display: none;
                                }

                                @include breakpoint(medium down) {
                                    /*margin-bottom: 0;*/
                                    padding: 26px 20px 0;
                                    width: 100%;
                                    cursor: pointer;
                                    pointer-events: all;

                                    i {
                                        display: block;
                                        float: right;
                                        font-size: rem-calc(14);
                                    }

                                    &[aria-expanded="false"] {
                                        margin-top: -1px;
                                        margin-bottom: 0;
                                        border-top: 1px solid $primary-color;
                                        border-bottom: 1px solid $primary-color;
                                        background: $white;
                                        padding: 26px 20px;

                                        &:before {
                                            position: absolute;
                                            top: -21px;
                                            background: $white;
                                            height: 20px;
                                            width: 100%;
                                        }

                                        i {
                                            &:before {
                                                content: "\f078";
                                            }
                                        }
                                    }
                                }
                            }

                            .accordion-content {

                                @include breakpoint(large up) {
                                    display: block !important;
                                }

                                @include breakpoint(medium down) {
                                    padding: 0 20px 0;

                                    &.expanded {
                                        display: block !important;
                                        margin-top: 5px;
                                        margin-bottom: -5px;
                                    }

                                    &:not(.expanded) {
                                        margin-bottom: -81%;
                                        height: 81%;
                                        overflow-y: auto;

                                        a {

                                            &:last-child {

                                                @include breakpoint(medium down) {
                                                    border-bottom: 0;
                                                }

                                            }

                                        }
                                    }
                                }

                                a {
                                    position: relative;
                                    display: block;
                                    margin-right: 46px;
                                    margin-bottom: 0;
                                    border-bottom: 1px solid $medium-gray;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                    line-height: 2.3;
                                    padding-top: 6px;
                                    padding-bottom: 6px;
                                    min-height: 33px;
                                    line-height: 1.45;
                                    font-weight: 400;
                                    color: $primary-color;

                                    @include breakpoint(medium down) {
                                        margin-right: 0;
                                    }

                                    &:hover{
                                        background-color: $secondary-color;
                                    }

                                }

                            }

                            &:last-of-type {

                                .accordion-content {

                                    a {

                                        &:last-child {

                                            @include breakpoint(medium down) {
                                                border-bottom: 0;
                                                padding-bottom: 10px;
                                            }

                                        }

                                    }

                                }

                            }

                        }

                        @include breakpoint(medium down) {
                            margin-left: 0;
                            margin-right: 0;
                        }


                        .cell {

                            &:last-child {

                                @include breakpoint(medium down) {

                                    .accordion-title {
                                        border-bottom: 0;
                                    }

                                }

                            }

                        }

                    }

                    &.expanded {
                        margin-right: -38px;

                        @include breakpoint(medium down) {
                            margin-right: 0;
                        }

                        .cell {
                            position: relative;
                            display: block;
                            margin-bottom: 0;
                            border-bottom: 1px solid $medium-gray;
                            padding-left: 10px;
                            padding-right: 10px;
                            line-height: 2.3;
                            padding-top: 6px;
                            padding-bottom: 6px;
                            min-height: 33px;
                            line-height: 1.45;
                            font-weight: 400;
                            color: $primary-color;

                            @include breakpoint(medium down) {
                                margin-left: 0;
                                margin-right: 0;
                            }

                            &:last-child {

                                @include breakpoint(medium down) {
                                    border-bottom: 0;
                                    padding-bottom: 10px;
                                }

                            }

                            &:after {
                                position: absolute;
                                top: 0;
                                right: 0;
                                background: #fff;
                                content: '';
                                width: 46px;
                                height: 103%;
                                z-index: 1;
                            }

                            @include breakpoint(medium down) {
                                &:after {
                                    content: none;
                                }
                            }

                            &:hover{
                                background-color: $secondary-color;
                            }

                        }

                    }

                }

            }

            .image-stretch {
                background-position: 0 0;
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                height: 100%;

                @include breakpoint(medium down) {
                    height: 205px;
                }

                &.home-schools {
                    background-image: url('/assets/img/image-home-schools.jpg');

                    @include breakpoint(large up) {
                        background-image: url('/assets/img/image-home-schools-lg.jpg') !important;
                    }

                }

                &.home-neighbourhoods {
                    background-image: url('/assets/img/image-home-neighbourhoods.jpg');

                    @include breakpoint(large up) {
                        background-image: url('/assets/img/image-home-neighbourhoods-lg.jpg') !important;
                    }

                }

            }

            /* Call to Action */

            &.cta {

                p {
                    display: inline-block;
                    margin-bottom: 0;

                    &:first-child {
                        margin-right: 15px;

                        @include breakpoint(medium down) {
                            margin-right: 0;
                        }
                    }

                    @include breakpoint(medium down) {
                        display: block;
                        margin-bottom: 14px;
                        line-height: 1.29;
                        text-align: center;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    i {
                        margin-right: 15px;
                        font-size: rem-calc(30);
                        vertical-align: middle;

                        @include breakpoint(medium down) {
                            display: block;
                            margin-bottom: 10px;
                        }

                    }

                }

                .button {

                    @include breakpoint(medium down) {
                        margin-top: 12px;
                    }

                }

            }

            /* Profile */

            &.profile {

                .logo {
                    position: relative;
                    top: -150px;
                    margin-bottom: -65px;
                    box-shadow: 0 4px 35px 0 rgba(0, 0, 0, 0.5);
                    border-radius: 1000px;
                    background: $white;
                    width: 200px;
                    height: 200px;
                    line-height: 200px;
                    text-align: center;

                    @include breakpoint(medium down) {
                        top: -94px;
                        margin-bottom: -46px;
                        margin-left: auto;
                        margin-right: auto;
                        width: 150px;
                        height: 150px;
                        line-height: 150px;
                    }

                    img {
                        max-width: 80%;
                    }
                }

                .large-3 {

                    .callout {
                        margin-top: 45px;
                        border: 0;
                        padding: 20px;

                        @include breakpoint(medium down) {
                            margin-bottom: 38px;
                            padding: 20px 20px 15px 20px;
                        }

                        a {
                            display: inline;
                            margin-bottom: 0;
                            color: $white !important;
                            font-weight: 400;
                        }
                    }

                    a {
                        display: flex;
                        margin-bottom: 15px;
                        text-align: left;
                        align-items: center;

                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        &:hover {
                            color: $black !important;

                            i {

                                &.bg-primary {
                                    background: $black !important;
                                }

                                &.primary-color {
                                    color: $black !important;
                                }

                            }

                        }

                        .fas {

                        }

                        span {

                        }

                        .fa-bookmark {
                            top: 0;
                            left: 0;
                            border-radius: 3px;
                            margin-left: 1px;
                            margin-right: 8px;
                            width: 16px;
                            height: 20px;
                            font-size: rem-calc(7);
                            text-align: center;
                            vertical-align: middle;

                            &:before {
                                position: relative;
                                top: 2px;
                                left: -2px;
                            }
                        }

                        .fa-chevron-left.bg-primary {
                            top: 0;
                            border-radius: 1000px;
                            width: 20px;
                            height: 20px;
                            line-height: 20px;
                            font-size: rem-calc(12);
                            text-align: center;
                            vertical-align: middle;
                        }
                    }

                }

                .large-9 {

                    .grid-x {
                        margin-top: 100px;

                        @include breakpoint(medium down) {
                            margin-top: 0;
                        }
                    }

                    .callout {
                        margin-bottom: 39px;
                        padding: rem-calc(25) rem-calc(25) rem-calc(30);

                        @include breakpoint(medium down) {
                            padding: rem-calc(24) rem-calc(20) rem-calc(30);
                        }
                    }

                    .post {

                        fieldset {
                            padding-top: 10px !important;
                        }

                        textarea {
                            margin-bottom: 20px;
                        }

                        .button {
                            width: 95px;
                            height: 35px;
                            line-height: 31px;
                        }

                    }

                    .card {
                        margin-bottom: 30px;

                        .card-divider {
                            position: relative;
                            align-items: center;
                            padding: rem-calc(25);
                            padding-bottom: 0;

                            @include breakpoint(medium down) {
                                padding: rem-calc(37) rem-calc(20) rem-calc(31);
                                padding-bottom: 0;
                            }

                            .logo {
                                display: inline-block;
                                top: 0;
                                margin-right: 15px;
                                margin-bottom: 0;
                                box-shadow: none;
                                border: 1px solid $medium-gray;
                                width: 60px;
                                height: 60px;
                                line-height: 60px;

                                @include breakpoint(medium down) {
                                    margin-right: 10px;
                                    width: 40px;
                                    height: 40px;
                                    line-height: 40px;
                                }
                            }

                            p {
                                display: inline-block;
                                flex: 1;
                                margin-bottom: 0;
                                line-height: 1.29;


                                &:last-child {
                                    flex: none;

                                    @include breakpoint(medium down) {
                                        position: relative;
                                        top: -25px;
                                        align-self: flex-start;
                                    }
                                }
                            }

                        }

                        .card-section {
                            padding: rem-calc(30) rem-calc(20);

                            /*@include breakpoint(medium down) {
                                padding: rem-calc(30) rem-calc(20);
                            }*/

                            p {
                                border-top: 1px solid $medium-gray;
                                border-bottom: 1px solid $medium-gray;
                                padding-top: rem-calc(25);
                                padding-bottom: rem-calc(25);

                                + img {
                                    margin-top: -25px;
                                }

                                + .button,
                                + .button + .button {
                                    margin-top: 0;
                                }

                                &:only-child {
                                    border-bottom: 0;
                                    padding-bottom: 0;
                                }
                            }

                            img {
                                margin-left: -20px;
                                max-width: 117%;
                            }

                            .button {
                                margin-right: 15px;

                                &:last-of-type {
                                    margin-right: 0;
                                }
                            }

                        }
                    }

                    .button {
                        margin-top: 30px;

                        &.white-color {
                            color: $white;
                        }

                        @include breakpoint(medium down) {
                            &.load-more {
                                margin-bottom: 40px;
                                width: 200px;
                            }
                        }

                        &:hover {
                            background: $black !important;

                            &.clear {
                                background: transparent !important;
                            }

                            i {
                                color: $primary-color !important;
                            }
                        }
                    }

                    .large-4 {
                        position: relative;

                        .tns-outer {
                            position: relative;

                            .profile-slider {
                                display: block;

                                /*@include breakpoint(medium down) {
                                    margin-left: 1.25rem;
                                }*/
                            }

                            .tns-controls {
                                position: absolute;
                                width: 100%;
                                height: 100%;

                                [data-controls="prev"],
                                [data-controls="next"] {
                                    position: absolute;
                                    top: 48%;
                                    transform: translateY(-50%);

                                    .button {
                                        @include breakpoint(medium down) {
                                            width: 24px;
                                            height: 24px;
                                            line-height: 20px;

                                            i {
                                                font-size: rem-calc(12);
                                            }
                                        }
                                    }
                                }

                                [data-controls="prev"] {
                                    left: -60px;

                                    @include breakpoint(medium down) {
                                        left: -17px;
                                        z-index: 1;
                                    }

                                    i {
                                        margin-right: 3px;
                                    }
                                }

                                [data-controls="next"] {
                                    right: -60px;

                                    @include breakpoint(medium down) {
                                        right: -17px;
                                        z-index: 1;
                                    }

                                    i {
                                        margin-left: 3px;
                                    }
                                }

                            }

                        }

                        .profile-slider {
                            display: none;
                            margin-left: 0;
                            list-style: none;

                            li {
                                margin-bottom: 30px;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                @include breakpoint(medium down) {
                                    margin-bottom: 0;
                                }
                            }

                            img {
                                max-width: 261px;
                                max-height: 261px;
                                width: 100%;
                            }

                        }

                    }

                }

            }

            .cards {
                padding: rem-calc(30) 0 0;

                @include breakpoint(medium down) {
                    padding: 0;
                }

                a.cell {
                    position: relative;
                    background-repeat: no-repeat;
                    background-position: 50% 30%;
                    background-size: 40%;
                    padding: 10.5% rem-calc(30) 10.5%;
                    text-align: center;
                    /*min-height: 130px;*/
                    height: 0;

                    @include breakpoint(medium only) {
                    }

                    @include breakpoint(small only) {
                        padding: rem-calc(22) rem-calc(15) 34%;
                        background-size: 38%;
                    }

                    /*@include breakpoint(large up) {
                        width: 261px;
                        height: 261px;
                    }*/

                    &:hover {
                        background-color: $tertiary-color;
                        color: $primary-color;
                    }

                    img {
                        margin-bottom: 19.5%;

                        @include breakpoint(medium down) {
                            max-width: 100%;
                            height: 48px;
                        }

                        @include breakpoint(small only) {
                            margin-bottom: 11%;
                        }
                    }

                    p {
                        position: absolute;
                        top: 75%;
                        left: 0;
                        right: 0;
                        line-height: 1.38;
                        font-size: rem-calc(16);
                        font-weight: 600;

                        @include breakpoint(medium down) {
                            top: 66%;
                            margin: 0 auto;
                            width: 78%;
                            line-height: 1.33;
                            font-size: rem-calc(12);
                        }
                    }
                }

            }

            /* Forms */

            form {

                * {
                    outline: 0;
                }

                fieldset {
                    position: relative;

                    /*input {
                        display: inline-block;
                    }

                    select {
                        display: inline-block;
                    }

                    button {
                        display: inline-block;
                    }*/

                    * + button {
                        margin-left: 2%;
                    }

                    button:not(:only-of-type) {
                        display: inline-block;
                        @include breakpoint(medium down) {
                            width: 48%;
                        }
                    }

                }

                input:not([type="radio"]) {
                    display: block;
                    margin-right: 15px;
                    margin-bottom: 30px;
                    width: 100%;
                    height: 45px;

                    @include breakpoint(medium down) {
                        width: 100% !important;
                    }
                }

                input[type="radio"] {
                    opacity: 0;
                    position: absolute;

                    + label {
                        position: relative;
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0 10px 0 0;
                        cursor: pointer;

                        @include breakpoint(small only) {
                            margin: 0 5px 0 0;
                        }

                        &:last-child {
                            margin: 0;
                        }

                        &:before {
                            content: '';
                            border: 2px solid $medium-gray;
                            display: inline-block;
                            border-radius: 50%;
                            vertical-align: middle;
                            width: 22px;
                            height: 22px;
                            line-height: 14px;
                            padding: 2px;
                            margin-top: -2px;
                            margin-right: 8px;
                            text-align: center;
                        }

                        span {
                            position: absolute;
                            top: 7px;
                            left: 6px;
                            display: block;
                            width: 22px;
                            height: 22px;
                            font-size: 10px;
                            color: $white;

                            &:before {

                            }

                        }

                    }

                    &:checked + label {

                        &:before {
                            border: 2px solid $primary-color;
                        }

                        span {
                            color: $primary-color;
                        }

                    }

                }

                select {
                    display: block;
                    margin-right: 15px;
                    margin-bottom: 30px;
                    width: 100%;
                    height: 45px;
                    color: $medium-gray;

                    appearance: none;
                    /* Some browsers will not display the caret when using calc, so we put the fallback first */
                    background: url("/assets/img/icon-arrow-down.svg") no-repeat 91%; /* !important used for overriding all other customisations */
                    background: url("/assets/img/icon-arrow-down.svg") no-repeat calc(100% - 20px); /* Better placement regardless of input width */

                    @include breakpoint(medium down) {
                        width: 100% !important;
                    }

                    &:not(.first) {
                        color: $primary-color;
                    }

                    option {
                        color: $black;

                        &:first-child {
                            color: $medium-gray;
                        }
                    }
                }

                textarea {
                    margin-bottom: 30px;
                    border-radius: 0;
                    border: 0;
                    border-bottom: 1px solid $medium-gray;
                    min-height: 70px;
                }

                label:not(.input-before):not(.input-date) {
                    font-size: rem-calc(14);

                    + input,
                    + select,
                    + textarea {
                        margin-top: rem-calc(10);
                    }

                }

                .image-upload {
                    position: relative;
                    display: inline-block;

                    &.drag-upload {
                        margin-bottom: 30px;
                        border: 1px solid $medium-gray;
                        box-sizing: border-box;
                        width: 100%;
                        height: 100%;
                        max-width: 554px;
                        min-height: 339px;
                        text-align: center;

                        label {
                            position: absolute;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 100%;

                            p {
                                margin-top: 17px;
                                margin-bottom: 51px;
                            }
                        }
                    }

                    label {
                        margin-bottom: 0;
                        cursor: pointer;

                        i {
                            font-size: rem-calc(19);
                        }

                        img  {

                        }

                        &:hover {
                            i {
                                color: $primary-color !important;
                            }
                        }
                    }

                    > input {
                        position: absolute;
                        top: 0;
                        width: 0;
                        height: 0;
                        visibility: hidden;
                        cursor: pointer;
                    }

                }

                p {
                    margin-top: 10px;

                    /*@include breakpoint(medium down) {
                        margin-top: 24px;
                    }*/

                    &.monthly-fee {
                        display: none;
                    }

                }

                button {
                    display: inline-block;

                    @include breakpoint(medium down) {
                        /*margin-left: 0 !important;*/
                        /*margin-bottom: 20px;*/
                    }

                    &:disabled {
                        background: $light-medium-gray;
                        color: #95989f;
                        opacity: 1;
                    }

                    @include breakpoint(medium up) {
                        + button {
                            margin-left: 20px;
                        }
                    }
                }

                label {

                    &.input-date {
                        position: absolute;
                        top: 4px;
                        right: 20px;
                        background: transparent;
                        font-size: 20px;

                        + input {
                            padding-right: rem-calc(40);
                        }
                    }

                    &.input-before {
                        position: absolute;
                        top: 10px;
                        left: 20px;
                        font-weight: 700;

                        + input {
                            padding-left: rem-calc(35);
                        }
                    }

                    &.input-after {
                        position: absolute;
                        top: 10px;
                        right: 20px;
                        font-weight: 700;

                        + input {
                            padding-right: rem-calc(35);
                        }
                    }

                }

            }

            &.register-options {

                .grid-padding-x {

                    @include breakpoint(medium down) {
                        margin-left: -rem-calc(-20);
                        margin-right: -rem-calc(-20);
                    }

                    .cell {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        padding-left: rem-calc(20);
                        padding-right: rem-calc(20);
                    }

                    h3 {
                        margin-bottom: rem-calc(10);
                    }

                    p {
                        flex: 1;
                        margin-bottom: rem-calc(20);

                        @include breakpoint(medium down) {
                            width: 89%;
                        }
                    }

                    svg {
                        flex: 0 1 96px;
                        margin-top: 20px;
                        margin-bottom: 30px;
                    }

                }

                .grid-padding-y {

                    @include breakpoint(medium down) {
                        margin-top: -rem-calc(-30);
                        margin-bottom: -rem-calc(-30);
                    }

                    .cell {
                        padding-top: rem-calc(30);
                        padding-bottom: rem-calc(30);
                    }

                }

            }

        }

    }

}

// Loading

.lds-dual-ring {
    position: absolute;
    top: 7px;
    left: auto;
    right: 0.5%;
    display: none;
    margin: 0 auto;
    width: 32px;
    height: 32px;
    z-index: 2;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: 2.5px solid $black;
    border-color: $black transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}